import React from 'react';
import Theme from '../../theme';

export const ArrowRight = ({ title, ...rest }) => (
  <svg
    width="54px"
    height="28px"
    viewBox="0 0 54 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <title>{title ?? ''}</title>
    <g stroke="none" strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-485.000000, -5221.000000)">
        <g transform="translate(480.000000, 5203.000000)">
          <path d="M45.1927968,18.5044444 L59,32.0074301 L45.1934127,45.495929 C44.5112199,46.1624048 43.4224323,46.1646612 42.737483,45.5010186 C42.0753383,44.8594713 42.0586408,43.80262 42.7001882,43.1404753 C42.710236,43.1301048 42.720418,43.1198652 42.7307315,43.1097589 L52.403,33.631 L6.69398207,33.6312857 C5.80766163,33.6312857 5.08032454,32.93706 5.00621793,32.0526849 L5,31.9036149 C5,30.9494486 5.75842161,30.1759441 6.69398207,30.1759441 L6.69398207,30.1759441 L52.193,30.175 L42.7299951,20.8907353 C42.0714756,20.2446584 42.0613889,19.1870741 42.7074657,18.5285546 L42.737483,18.4987247 L42.737483,18.4987247 C43.4223325,17.8351789 44.5110462,17.8377151 45.1927968,18.5044444 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export const Chevron = ({
  size = '40',
  direction = 'right',
  color = Theme.colors.white,
  ...rest
}) => {
  const getTransform = () => {
    switch (direction) {
      case 'up':
        return 'rotate(-90 20 20)';
      case 'down':
        return 'rotate(90 20 20)';
      case 'left':
        return 'rotate(180 20 20)';
      default:
        return 'rotate(0)';
    }
  };

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 40 40"
      width={size}
      height={size}
      {...rest}
    >
      <path
        fill={color}
        transform={getTransform()}
        d="M25.45 20.01L25.45 20.01C18.65 13.34 14.87 9.63 14.12 8.89C13.46 8.24 13.45 7.19 14.09 6.53C14.1 6.52 14.11 6.51 14.12 6.5C14.81 5.84 15.9 5.84 16.58 6.5C17.5 7.4 22.1 11.91 30.39 20.01L30.39 20.01C22.1 28.1 17.5 32.6 16.58 33.5C15.9 34.16 14.81 34.16 14.12 33.5C13.46 32.86 13.44 31.8 14.09 31.14C14.1 31.13 14.11 31.12 14.12 31.11C15.63 29.63 19.4 25.93 25.45 20.01Z"
      />
    </svg>
  );
};

export const ChevronUp = () => {
  return (
    <svg
      width="29px"
      height="18px"
      viewBox="0 0 29 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-338.000000, -5226.000000)">
          <g transform="translate(320.000000, 5203.000000)">
            <path
              d="M24.5013012,43.1070324 L35.8313531,32.0047035 L35.8313531,32.0047035 L24.5005648,20.8880088 C23.8420453,20.2419319 23.8319585,19.1843475 24.4780354,18.525828 C24.4879148,18.5157584 24.4979214,18.5058143 24.5080527,18.4959981 C25.1929021,17.8324523 26.2816159,17.8349885 26.9633664,18.5017179 L40.7705697,32.0047035 L40.7705697,32.0047035 L26.9639823,45.4932024 C26.2817896,46.1596782 25.193002,46.1619346 24.5080527,45.4982921 C23.8459079,44.8567447 23.8292105,43.7998934 24.4707578,43.1377487 C24.4808057,43.1273783 24.4909877,43.1171386 24.5013012,43.1070324 Z"
              transform="translate(32.385285, 31.997273) rotate(-90.000000) translate(-32.385285, -31.997273)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Facebook = () => {
  return (
    <svg
      width="13px"
      height="24px"
      viewBox="0 0 13 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Facebook</title>
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-980.000000, -9559.000000)">
          <g transform="translate(0.000000, 9498.000000)">
            <g transform="translate(965.000000, 52.000000)">
              <g>
                <path d="M24.5,13 L27.5,13 C27.7761424,13 28,12.7761424 28,12.5 L28,9.5 C28,9.22385763 27.7761424,9 27.5,9 L24.5,9 C21.4638047,9.0033068 19.0033068,11.4638047 19,14.5 L19,18 L15.5,18 C15.2238576,18 15,18.2238576 15,18.5 L15,21.5 C15,21.7761424 15.2238576,22 15.5,22 L19,22 L19,32.5 C19,32.7761424 19.2238576,33 19.5,33 L22.5,33 C22.7761424,33 23,32.7761424 23,32.5 L23,22 L26.5,22 C26.7152881,21.9996485 26.9063825,21.8620605 26.975,21.658 L27.975,18.658 C28.0253413,18.5054488 27.9994769,18.3380095 27.9054509,18.2077588 C27.811425,18.0775082 27.6606426,18.0002451 27.5,18 L23,18 L23,14.5 C23,13.673 23.673,13 24.5,13 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const LinkedIn = () => {
  return (
    <svg
      width="20px"
      height="18px"
      viewBox="0 0 20 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>LinkedIn</title>
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-1081.000000, -9561.000000)">
          <g transform="translate(0.000000, 9498.000000)">
            <g transform="translate(965.000000, 52.000000)">
              <g transform="translate(103.500000, 0.000000)">
                <g transform="translate(13.000000, 11.000000)">
                  <circle cx="2.25" cy="2.25" r="2.25"></circle>
                  <path d="M4.125,6 L0.375,6 C0.167893219,6 0,6.16789322 0,6.375 L0,17.625 C0,17.8321068 0.167893219,18 0.375,18 L4.125,18 C4.33210678,18 4.5,17.8321068 4.5,17.625 L4.5,6.375 C4.5,6.16789322 4.33210678,6 4.125,6 Z"></path>
                  <path d="M16.29775,5.48325 C14.695,4.93425 12.69025,5.4165 11.488,6.28125 C11.4452587,6.11571381 11.295965,6.00004203 11.125,6 L7.375,6 C7.16789322,6 7,6.16789322 7,6.375 L7,17.625 C7,17.8321068 7.16789322,18 7.375,18 L11.125,18 C11.3321068,18 11.5,17.8321068 11.5,17.625 L11.5,9.54 C12.106,9.018 12.88675,8.8515 13.52575,9.123 C14.14525,9.38475 14.5,10.02375 14.5,10.875 L14.5,17.625 C14.5,17.8321068 14.6678932,18 14.875,18 L18.625,18 C18.8321068,18 19,17.8321068 19,17.625 L19,10.11975 C18.95725,7.038 17.5075,5.89725 16.29775,5.48325 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Pinterest = () => {
  return (
    <svg
      width="21px"
      height="24px"
      viewBox="0 0 21 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Pinterest</title>
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-1132.000000, -9559.000000)">
          <g transform="translate(0.000000, 9498.000000)">
            <g transform="translate(965.000000, 52.000000)">
              <g transform="translate(155.500000, 0.000000)">
                <g transform="translate(12.000000, 9.000000)">
                  <path d="M10.5,0 C1.886,0 0,6.497 0,9 C0,11.813 2.691,16 4.5,16 C4.72331651,15.999724 4.91948779,15.8516779 4.981,15.637 L5.375,14.257 L4.01,20.398 C3.994,20.477 3.629,22.336 5.146,23.853 C5.30555462,24.0132563 5.55281053,24.0464698 5.749,23.934 C5.814,23.896 7.363,22.996 7.954,21.709 C8.366,20.814 9.308,18.392 9.742,17.265 C10.5818549,17.7454744 11.5324203,17.9987977 12.5,18 C18.036,18 20,13.152 20,9 C20,5.887 18.015,0 10.5,0 Z M5.978,11.545 L5.932,11.752 C5.89947564,11.6947331 5.85597953,11.6444408 5.804,11.604 C5.771,11.578 5,10.954 5,9.5 C5,6.064 7.851,4.5 10.5,4.5 C13.279,4.5 15,6.576 15,8.5 C15,10.556 13.122,14 12,14 C10.804,14 10.561,13.094 10.512,12.684 C11.639,11.387 12,9.069 12,8.465 C12.001,8.107 12.003,7.361 11.406,6.762 C10.902,6.256 10.092,6 9,6 C6.237,6 5.5,8.2 5.5,9.5 C5.5,10.26 5.834,11.183 5.978,11.545 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Youtube = () => {
  return (
    <svg
      width="25px"
      height="18px"
      viewBox="0 0 25 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Youtube</title>
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-1182.000000, -9562.000000)">
          <g transform="translate(0.000000, 9498.000000)">
            <g transform="translate(965.000000, 52.000000)">
              <g transform="translate(207.500000, 0.000000)">
                <g transform="translate(10.000000, 12.000000)">
                  <path d="M20.803,0.764 L15.509,0.304 C13.1740706,0.100999132 10.8259294,0.100999132 8.491,0.304 L3.197,0.764 C1.38366492,0.911927841 -0.0096834366,2.43166688 -5.01652869e-05,4.251 L-5.01652869e-05,13.75 C-0.0096834366,15.5693331 1.38366492,17.0890722 3.197,17.237 L8.491,17.697 C9.655,17.799 10.827,17.849 12,17.849 C13.173,17.849 14.345,17.799 15.509,17.697 L20.803,17.237 C22.615878,17.0881383 24.0087135,15.5689586 24.0000502,13.75 L24.0000502,4.251 C24.0096834,2.43166688 22.6163351,0.911927841 20.803,0.764 Z M8.99999596,4.5 C8.99926456,4.31685588 9.09891576,4.14802602 9.25961065,4.06016557 C9.42030555,3.97230511 9.61621782,3.97953468 9.77,4.079 L16.77,8.579 C16.9131412,8.67084544 16.9997805,8.82915375 16.9999831,8.99922713 C17.000185,9.16930051 16.9139224,9.32781441 16.771,9.42 L9.771,13.92 C9.6166948,14.0175629 9.42179948,14.0244415 9.261,13.938 C9.09987435,13.8513138 8.99955627,13.6829639 8.99999596,13.5 L8.99999596,4.5 L8.99999596,4.5 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Instagram = () => {
  return (
    <svg
      width="25px"
      height="24px"
      viewBox="0 0 25 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Instagram</title>
      <g stroke="none" strokeWidth="1">
        <g transform="translate(-1026.000000, -9559.000000)">
          <g transform="translate(0.000000, 9498.000000)">
            <g transform="translate(965.000000, 52.000000)">
              <g transform="translate(61.500000, 9.000000)">
                <path d="M12,2.162 C15.204,2.162 15.584,2.174 16.849,2.232 C18.215,2.294 19.482,2.568 20.457,3.543 C21.432,4.518 21.706,5.785 21.768,7.151 C21.826,8.416 21.838,8.796 21.838,12 C21.838,15.204 21.826,15.584 21.768,16.849 C21.706,18.215 21.432,19.482 20.457,20.457 C19.482,21.432 18.215,21.706 16.849,21.768 C15.584,21.826 15.204,21.838 12,21.838 C8.796,21.838 8.416,21.826 7.151,21.768 C5.785,21.706 4.518,21.432 3.543,20.457 C2.568,19.482 2.294,18.215 2.232,16.849 C2.174,15.584 2.162,15.204 2.162,12 C2.162,8.796 2.174,8.416 2.232,7.151 C2.294,5.785 2.568,4.518 3.543,3.543 C4.518,2.568 5.785,2.294 7.151,2.232 C8.416,2.174 8.796,2.162 12,2.162 M12,0 C8.741,0 8.332,0.014 7.052,0.072 C5.102,0.161 3.389,0.639 2.014,2.014 C0.639,3.389 0.161,5.102 0.072,7.052 C0.014,8.332 0,8.741 0,12 C0,15.259 0.014,15.668 0.072,16.948 C0.161,18.898 0.639,20.611 2.014,21.986 C3.389,23.361 5.102,23.839 7.052,23.928 C8.332,23.986 8.741,24 12,24 C15.259,24 15.668,23.986 16.948,23.928 C18.898,23.839 20.611,23.361 21.986,21.986 C23.361,20.611 23.839,18.898 23.928,16.948 C23.986,15.668 24,15.259 24,12 C24,8.741 23.986,8.332 23.928,7.052 C23.839,5.102 23.361,3.389 21.986,2.014 C20.611,0.639 18.898,0.161 16.948,0.072 C15.668,0.014 15.259,0 12,0 L12,0 Z"></path>
                <path d="M12,5.838 C8.597,5.838 5.838,8.597 5.838,12 C5.838,15.403 8.597,18.162 12,18.162 C15.403,18.162 18.162,15.403 18.162,12 C18.162,8.597 15.403,5.838 12,5.838 Z M12,16 C9.791,16 8,14.209 8,12 C8,9.791 9.791,8 12,8 C14.209,8 16,9.791 16,12 C16,14.209 14.209,16 12,16 Z"></path>
                <circle cx="18.406" cy="5.594" r="1.44"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Orb1 = (props) => {
  return (
    <svg
      height="347"
      viewBox="0 0 379 347"
      width="379"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ''}
    >
      <path
        d="M350.45 83.65C376.31 124.3 384.11 164.28 373.8 203.77C363.38 243.34 336 278.06 291.45 308.09C243.76 340.23 196.04 352.05 148.19 343.61C100.44 335.12 61.41 308.53 31.14 263.69C4.03 223.54 -5.25 183.91 3.24 144.99C11.85 106.01 37.13 72.38 79.09 44.1C118.78 17.36 157.15 2.88 194.13 0.57C231.13 -1.74 264.6 8.24 294.52 30.88C321.44 50.15 338.92 68.09 350.45 83.65"
        fillRule="evenodd"
        fill={props.fill ? props.fill : ''}
      />
    </svg>
  );
};

export const Orb2 = (props) => {
  return (
    <svg
      width="309px"
      height="336px"
      viewBox="0 0 309 336"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ''}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill={props.fill ? props.fill : ''}
        fillRule="evenodd"
      >
        <g
          transform="translate(-907.000000, -7680.000000)"
          fill={props.fill ? props.fill : ''}
        >
          <g transform="translate(64.000000, 7632.000000)">
            <g>
              <path
                d="M835.184963,214.707358 C833.354409,171.767786 845.108915,137.403294 870.560558,111.485536 C896.129612,85.56243 932.588597,70.941745 980.153655,67.6074373 C1031.06761,64.0405035 1072.98624,76.1825172 1106.01894,104.036152 C1138.9449,131.892461 1157.1357,169.831909 1160.49527,217.980167 C1163.50528,261.082845 1153.04764,295.837721 1129.24777,322.124473 C1105.32514,348.405876 1070.96889,363.117472 1026.16835,366.256588 C983.798753,369.224576 947.8868,363.299295 918.435156,348.593046 C888.978176,333.886798 867.683973,311.252544 854.739339,280.420225 C842.608581,253.526507 837.135597,231.876235 835.184963,214.707358"
                transform="translate(998.000000, 217.000000) rotate(-120.000000) translate(-998.000000, -217.000000)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Orb3 = (props) => {
  return (
    <svg
      width="433px"
      height="396px"
      viewBox="0 0 433 396"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ''}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill={props.fill ? props.fill : ''}
        fillRule="evenodd"
      >
        <g
          transform="translate(-561.000000, -7844.000000)"
          fill={props.fill ? props.fill : ''}
        >
          <g transform="translate(64.000000, 7632.000000)">
            <g>
              <path d="M497.245361,406.973713 C494.817058,350.293477 510.409876,304.932348 544.172485,270.720907 C578.090846,236.502408 626.45513,217.203103 689.55206,212.801817 C757.091439,208.093465 812.698152,224.120923 856.517333,260.887721 C900.194922,297.658049 924.325736,347.73812 928.782344,411.293821 C932.775238,468.189355 918.902763,514.065792 887.33129,548.764304 C855.596985,583.455756 810.022064,602.875063 750.592366,607.018696 C694.387426,610.936441 646.748802,603.115069 607.680032,583.702821 C568.604183,564.290573 540.356581,534.413358 523.185013,493.714697 C507.093083,458.214989 499.832954,429.63663 497.245361,406.973713"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Orb4 = (props) => {
  return (
    <svg
      width="309px"
      height="337px"
      viewBox="0 0 309 337"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className ? props.className : ''}
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill={props.fill ? props.fill : ''}
        fillRule="evenodd"
      >
        <g
          transform="translate(-676.000000, -11348.000000)"
          fill={props.fill ? props.fill : ''}
        >
          <g transform="translate(64.000000, 11301.000000)">
            <g transform="translate(876.500000, 321.500000) scale(-1, 1) translate(-876.500000, -321.500000) translate(554.000000, 0.000000)">
              <g>
                <path
                  d="M270.184963,214.560051 C268.354409,171.620479 280.108915,137.255986 305.560558,111.338228 C331.129612,85.4151226 367.588597,70.7944375 415.153655,67.4601299 C466.067614,63.8931961 507.98624,76.0352098 541.018939,103.888845 C573.9449,131.745154 592.1357,169.684601 595.495274,217.83286 C598.505282,260.935538 588.04764,295.690414 564.247766,321.977165 C540.325143,348.258568 505.968895,362.970165 461.168347,366.10928 C418.798753,369.077269 382.8868,363.151988 353.435156,348.445739 C323.978176,333.73949 302.683973,311.105237 289.739339,280.272918 C277.608581,253.3792 272.135597,231.728928 270.184963,214.560051"
                  transform="translate(433.000000, 216.852693) rotate(-120.000000) translate(-433.000000, -216.852693)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PlayButton = ({
  backgroundColor = Theme.colors.brand,
  triangleColor = Theme.colors.white,
  size = '114px',
  ...rest
}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 230 230"
    width={size}
    height={size}
    {...rest}
  >
    <path
      fill={backgroundColor}
      d="M0.13 113.4C-1.16 83.34 7.13 59.28 25.09 41.14C43.13 22.99 68.85 12.76 102.41 10.43C138.33 7.93 167.9 16.43 191.21 35.93C214.44 55.42 227.27 81.98 229.64 115.69C231.77 145.86 224.39 170.19 207.6 188.59C190.72 206.98 166.48 217.28 134.87 219.48C104.98 221.56 79.64 217.41 58.87 207.12C38.08 196.82 23.06 180.98 13.93 159.39C5.37 140.57 1.51 125.41 0.13 113.4"
    />
    <path
      fill="none"
      stroke={triangleColor}
      strokeWidth="6"
      d="M148.99 112.67C149.55 113.01 149.54 113.83 148.98 114.17C137.84 120.88 104.78 140.79 93.43 147.63C93.36 147.67 93.29 147.62 93.29 147.55C93.29 133.75 93.29 93.11 93.29 79.46C93.29 79.06 93.73 78.81 94.07 79.02C105.3 85.9 137.85 105.84 148.99 112.67Z"
    />
  </svg>
);
