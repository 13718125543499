import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 18px;
  line-height: 32px;
  margin-top: -1px;
`;

export const Title = styled.h3`
  margin: 0 0 20px;
  line-height: 58px;
`;

export const Toggle = styled.button`
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: none;
  border: none;
  border-top: 1px solid ${(props) => props.theme.colors.greyLighter};
  font-family: ${(props) => (props.isopen ? 'AdelleBold' : 'AdelleRegular')};
  text-align: left;
  transition: padding 0.2s ease;

  &:hover {
    background-color: ${(props) => props.theme.colors.greyLightest};
  }

  span {
    flex: 1 0;
  }

  svg {
    flex: 0 1 auto;
    width: 16px;
    height: 10px;
    margin: 0 20px 0 16px;
    fill: ${(props) => props.theme.colors.brand};
    transition: transform 0.2s ease;
    transform: ${(props) => (props.isopen ? 'rotate(0)' : 'rotate(-180deg)')};
  }
`;

export const Content = styled.div`
  height: ${(props) => (props.isopen ? `${props.height}px` : 0)};
  overflow: hidden;
  transition: height 0.2s ease;
  border-bottom: 1px solid ${(props) => props.theme.colors.greyLighter};

  > div {
    padding: 4px 0 20px;
    padding-left: 52px;
  }

  p {
    color: ${(props) => props.theme.colors.greyDarkest};
    font-family: AdelleSansLight;
    line-height: 32px;

    &:last-child {
      margin: 0;
    }
  }
`;
