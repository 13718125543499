import React from 'react';
import { useMedia } from 'react-use-media';
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { formatSlug } from '../../utils';
import { ChevronUp } from '../../images/svg/Icons';
import Theme from '../../theme';
import Settings from '../../settings';

const MenuWrapper = styled.div`
  position: absolute;
  display: inline-block;
  left: 0;
  top: ${Settings.hasSiteSwitch ? '100px' : '60px'};
  width: 100vw;
  height: 100vh;
  padding-top: 30px;
  background-color: ${(props) => props.theme.colors.backgroundColor};

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    top: 0;
    left: 0;
    padding-top: 0;
    background-color: transparent;
    margin-left: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '60px';
      } else if (Settings.headerLogoOverflowMode === 'none') {
        return '30px';
      } else {
        return '118px';
      }
    }};
    transition: margin 0.3s ease-out;

    &.breadcrumb-visible {
      top: 0;
    }
  }
`;

const ToggleText = styled.div`
  position: relative;
  display: block;
  padding: 10px 0;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  font-size: 28px;
  font-family: AdelleBold;

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.brand};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const sharedMainLink = ({ theme }) => css`
  position: relative;
  display: block;
  padding: 10px 0;
  color: ${theme.colors.black};
  text-decoration: none;
  font-size: 28px;
  font-family: AdelleBold;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
  }

  @media (min-width: ${theme.breakpoints.l}) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const MainLink = styled(AniLink)`
  ${sharedMainLink}

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`;

const MainLinkDummy = styled.span`
  ${sharedMainLink}
  cursor: pointer;

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`;

const sharedSubLink = ({ theme }) => css`
  position: relative;
  flex: 1 1 auto;
  display: block;
  padding: 10px 0;
  color: ${theme.colors.black};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${theme.colors.brand};
  }
`;

const SubLink = styled(AniLink)`
  ${sharedSubLink}

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`;

const SubLinkDummy = styled.span`
  ${sharedSubLink}
  cursor: pointer;

  &.menu-item--active {
    color: ${(props) => props.theme.colors.brand};
  }
`;

const MainNavigation = styled.ul`
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0 16px;
  max-height: calc(100vh - 140px);
  overflow: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-height: none;
    overflow: visible;
  }
`;

const SubNavigation = styled.ul`
  position: relative;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MainNavigationItem = styled.li`
  position: relative;
  display: block;
  padding: 12px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-right: 26px;
    padding: 0;

    &:hover {
      color: ${({ theme }) => theme.colors.brand};

      > div > a {
        text-decoration: none;
      }

      > div > button > svg {
        fill: ${({ theme }) => theme.colors.black};
      }

      [class^='MainMenu__SubNavigationContainer'] {
        pointer-events: all;
        display: block;
        opacity: 1;
        height: auto;
      }
    }

    /* Seperate for IE fix */
    &:focus-within {
      color: ${({ theme }) => theme.colors.brand};

      > div > a {
        text-decoration: none;
      }

      > div > button > svg {
        fill: ${({ theme }) => theme.colors.black};
      }

      [class^='MainMenu__SubNavigationContainer'] {
        pointer-events: all;
        display: block;
        opacity: 1;
        height: auto;
      }
    }
  }
`;

const SubNavigationItem = styled.li`
  position: relative;
  display: block;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    padding: 0 20px;

    > div > button {
      width: 10px;
      margin-left: 16px;
    }

    > div > button > svg {
      transform: rotate(90deg);
    }

    &:hover {
      [class^='MainMenu__SubNavigationDetailContainer'] {
        pointer-events: all;
        height: auto;
        opacity: 1;
      }
    }

    &:focus-within {
      [class^='MainMenu__SubNavigationDetailContainer'] {
        pointer-events: all;
        height: auto;
        opacity: 1;
      }
    }
  }
`;

const SubNavigationContainer = styled.div`
  position: relative;
  display: none;

  &.activated {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    pointer-events: none;
    display: block;
    position: absolute;
    height: 0;
    opacity: 0;
    left: 0;
    top: 50px;
    padding: 20px 0;
    white-space: nowrap;
    background: ${(props) => props.theme.colors.white};
    border-radius: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.24);
  }
`;

const SubNavigationDetailContainer = styled.div`
  position: relative;
  display: none;

  &.activated {
    display: block;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    pointer-events: none;
    display: block;
    position: absolute;
    top: -20px;
    right: 0;
    left: auto;
    height: 0;
    padding: 20px 0;
    background: ${(props) => props.theme.colors.white};
    transform: translateX(calc(100% - 2px));
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    opacity: 0;
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Toggle = styled.button`
  position: relative;
  z-index: 2;
  background: transparent;
  border: none;

  svg {
    fill: ${(props) => props.theme.colors.brand};
    transform: rotate(90deg);
    transition: transform 0.1s ease-in-out;
    width: 16px;
  }

  &.activated {
    svg {
      transform: rotate(180deg);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: inline-block;
    pointer-events: none;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    margin-left: 3px;
    padding: 0;

    svg {
      fill: ${(props) => props.theme.colors.brand};
      transform: rotate(180deg);
      transition: transform 0.1s ease-in-out;
      width: 10px;
    }

    &.activated {
      svg {
        transform: rotate(360deg);
      }
    }
  }
`;

export default function Menu(props) {
  const isMobile = useMedia({
    maxWidth: Theme.breakpoints.l,
  });

  const toggleItem = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const toggleElement = event.currentTarget;
    const toggleButton =
      toggleElement.children[toggleElement.children.length - 1];
    const subnavigationElement = toggleElement.nextSibling;

    if (subnavigationElement.classList.contains('activated')) {
      toggleButton.classList.remove('activated');
      subnavigationElement.classList.remove('activated');
    } else {
      toggleButton.classList.add('activated');
      subnavigationElement.classList.add('activated');
    }
  };

  const startsWith = (string, stringToCompare) => {
    return string ? string.indexOf(stringToCompare, 0) === 0 : null;
  };

  const formattedPath = (originalPath, pathToCompare) => {
    return startsWith(originalPath, pathToCompare)
      ? originalPath
      : `/${originalPath}`;
  };

  const currentPath = formattedPath(props?.location?.pathname, '/');
  const corporatePaths = ['/co%C3%B6peratie', '/cooperatie'];

  const isMainNavItemActive = (link, childLinks) => {
    const cleanLink = formattedPath(link?.slug, '/');
    const childLinksArray = childLinks?.map((item) =>
      formattedPath(item?.link?.slug, '/')
    );
    const hasActiveSubItem = childLinksArray?.some((path) =>
      currentPath.startsWith(path)
    );
    const isCorporateRootItem = corporatePaths.some(
      (path) => currentPath === path
    );
    const active =
      (!isCorporateRootItem && cleanLink === currentPath) || hasActiveSubItem;

    if (active) {
      return 'menu-item--active';
    }
    return null;
  };

  const isSubNavItemActive = (link) => {
    const cleanLink = formattedPath(link?.slug, '/');
    const nonCorporateLink = startsWith(cleanLink, '/cooperatie')
      ? formattedPath(cleanLink.replace('/cooperatie', ''), '/')
      : cleanLink;

    const nonCorporatePath = startsWith(currentPath, '/cooperatie')
      ? formattedPath(currentPath.replace('/cooperatie', ''), '/')
      : currentPath;

    const active = startsWith(nonCorporatePath, nonCorporateLink);

    if (active) {
      return 'menu-item--active';
    }
    return null;
  };

  const getDropdownProps = (subitems) => {
    if (isMobile && subitems) {
      return { onClick: toggleItem };
    }

    return { tabIndex: -1 };
  };

  const MenuItemRendered = ({ link, title, childLinks }) => {
    if (link) {
      return (
        <MainLink
          fade="true"
          duration={2}
          to={formatSlug(link.slug)}
          className={isMainNavItemActive(link, childLinks)}
        >
          {title}
        </MainLink>
      );
    } else {
      return <MainLinkDummy>{title}</MainLinkDummy>;
    }
  };

  const SubMenuItemRendered = ({ link, title }) => {
    if (link) {
      return (
        <SubLink
          fade="true"
          duration={2}
          to={formatSlug(link.slug)}
          className={isSubNavItemActive(link)}
        >
          {title}
        </SubLink>
      );
    } else {
      return <SubLinkDummy>{title}</SubLinkDummy>;
    }
  };

  return (
    <MenuWrapper sticky={props.sticky}>
      <MainNavigation>
        {props.data.subitems.length > 0 &&
          props.data.subitems.map((item) => (
            <MainNavigationItem key={item.id}>
              <ItemWrapper {...getDropdownProps(item.subitems)}>
                {item.subitems ? (
                  <>
                    {isMobile ? (
                      <ToggleText
                        className={isMainNavItemActive(
                          item?.link,
                          item?.subitems
                        )}
                      >
                        {item.title}
                      </ToggleText>
                    ) : (
                      <MenuItemRendered
                        link={item.link}
                        title={item.title}
                        childLinks={item.subitems}
                      />
                    )}
                    <Toggle aria-label="Toggle">
                      <ChevronUp />
                    </Toggle>
                  </>
                ) : (
                  <MenuItemRendered link={item.link} title={item.title} />
                )}
              </ItemWrapper>
              {item.subitems && (
                <SubNavigationContainer>
                  <SubNavigation>
                    {item.subitems.map((currentMainItem) => (
                      <SubNavigationItem
                        key={`${item.id}-${currentMainItem.id}`}
                      >
                        <ItemWrapper {...getDropdownProps()}>
                          <SubMenuItemRendered
                            link={currentMainItem.link}
                            title={currentMainItem.title}
                            parent={item.title}
                          />
                          {currentMainItem.subitems && (
                            <Toggle aria-label="Toggle">
                              <ChevronUp />
                            </Toggle>
                          )}
                        </ItemWrapper>
                        {currentMainItem.subitems && (
                          <SubNavigationDetailContainer>
                            <SubNavigation>
                              {currentMainItem.subitems.map(
                                (currentSubItem) => (
                                  <SubNavigationItem
                                    key={`${item.id}-${currentMainItem.id}-${currentSubItem.id}`}
                                  >
                                    <ItemWrapper {...getDropdownProps()}>
                                      <SubMenuItemRendered
                                        link={currentSubItem.link}
                                        title={currentSubItem.title}
                                      />
                                      {currentSubItem.subitems && (
                                        <Toggle aria-label="Toggle">
                                          <ChevronUp />
                                        </Toggle>
                                      )}
                                    </ItemWrapper>
                                  </SubNavigationItem>
                                )
                              )}
                            </SubNavigation>
                          </SubNavigationDetailContainer>
                        )}
                      </SubNavigationItem>
                    ))}
                  </SubNavigation>
                </SubNavigationContainer>
              )}
            </MainNavigationItem>
          ))}
      </MainNavigation>
    </MenuWrapper>
  );
}
