import styled from 'styled-components';
import typography from '../../typography';

export const TableWrapper = styled.div`
  margin-bottom: 20px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 3px;
    background: ${(props) =>
      props.theme.colors.backgroundColor === '#FFFFFF'
        ? props.theme.colors.greyLightest
        : props.theme.colors.white};
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: ${(props) => props.theme.colors.brand};
    border-radius: 3.5px;
  }
`;

export const TableElement = styled.table`
  margin-bottom: 10px;
  border-collapse: collapse;
  border-width: 0;
  min-width: 100%;
`;

export const TableRow = styled.tr`
  display: flex;
  border-bottom: 1px solid
    ${(props) =>
      props.theme.colors.backgroundColor === '#FFFFFF'
        ? props.theme.colors.greyLighter
        : props.theme.colors.white};

  &:last-child {
    border-bottom: none;
  }
`;

export const TableHead = styled.thead`
  background-color: ${(props) =>
    props.theme.colors.backgroundColor === '#FFFFFF'
      ? props.theme.colors.greyLightest
      : props.theme.colors.white};
`;
export const TableBody = styled.tbody``;

export const TableHeadColumn = styled.th`
  white-space: nowrap;
  text-align: left;
  padding: 15px;
  width: 200px;

  &:last-child {
    flex: 1;
  }
`;

export const TableColumn = styled.td`
  ${typography.body};
  text-align: left;
  padding: 15px;
  width: 200px;

  &:last-child {
    flex: 1;
  }
`;
