import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use-media';
import { graphql, useStaticQuery } from 'gatsby';
import parser from 'html-react-parser';
import Helmet from 'react-helmet';
import Container from '../Container';
import Favicon from '../Favicon';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { Wrapper, SkipContainer, SkipLink } from './styles';

const SkipLinks = () => (
  <Container>
    <SkipContainer id="skiplinks">
      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#navigation"
        content="Navigation"
      />

      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#main"
        content="Main content"
      />

      <SkipLink
        variation="secondary"
        target="_self"
        hyperlink="#footer"
        content="Footer"
      />
    </SkipContainer>
  </Container>
);

const Layout = ({ template, location, children }) => {
  const ScriptsQuery = useStaticQuery(graphql`
    query {
      allContentfulSettings(filter: { node_locale: { eq: "nl-NL" } }) {
        edges {
          node {
            ...ScriptsFragment
            ...FooterFragment
          }
        }
      }
    }
  `);

  const [corporate, setCorporate] = useState('consumer');
  const [corporateCheckRunning, setCorporateCheckRunning] = useState(true);
  const [isMenuToggled, setMenuToggled] = useState(false);

  const settings = ScriptsQuery.allContentfulSettings.edges[0].node;
  const { scriptsHead } = settings.scriptsHead;
  const { scriptsBody } = settings.scriptsBody;
  const isClient = typeof window === 'object';
  const isMobile = useMedia({
    maxWidth: '1023px',
  });

  useEffect(() => {
    const corporatePaths = ['/co%C3%B6peratie', '/cooperatie'];
    const isCorporateItem = corporatePaths.some((path) =>
      location.pathname.startsWith(path)
    );

    if (!isClient) {
      return;
    }

    setCorporate(isCorporateItem);
    setCorporateCheckRunning(false);
  }, [isClient, location, corporateCheckRunning]);

  useEffect(() => {
    if (isMobile) {
      setMenuToggled(false);
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuToggled(!isMenuToggled);
  };

  const renderScript = (value) => {
    const terms = ['<script', '</script>'];
    const containsScript = terms
      .map((term) => value.includes(term))
      .includes(true);
    return containsScript ? parser(value) : null;
  };

  return (
    <Wrapper template={template}>
      <Helmet>
        <html lang="nl" />
        {renderScript(scriptsHead)}
      </Helmet>

      <Favicon />
      <SkipLinks />

      <Header
        isCorporate={corporate}
        toggleMenu={toggleMenu}
        isMenuToggled={isMenuToggled}
        isMobile={isMobile}
        location={location}
        corporateCheckRunning={corporateCheckRunning}
      />

      <div id="main">{children}</div>

      <Footer
        data={settings.footerData}
        menu={settings.footerMenu}
        submenus={settings.footerSubMenus}
      />

      {renderScript(scriptsBody)}
    </Wrapper>
  );
};

export default Layout;
