import styled from 'styled-components';
import Button from '../Button/Button';
import Settings from '../../settings';

export const Wrapper = styled.main`
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;

  #main {
    margin-top: 60px;

    @media (min-width: ${(props) => props.theme.breakpoints.l}) {
      margin-top: ${Settings.hasSiteSwitch ? '130px' : '90px'};
    }
  }
`;

export const SkipContainer = styled.div`
  position: absolute;
  top: 0;
  width: 300px;
`;

export const SkipLink = styled(Button)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  margin-top: 0;
  opacity: 0;
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;

  &:first-child {
    position: relative;
  }

  &:focus {
    opacity: 1;
    z-index: 15;
  }
`;
