import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { formatSlug } from '../../utils';
import Container from '../Container';

import { Wrapper, ContainerInner, StyledLink, StyledLinkDummy } from './styles';

const SiteSwitch = ({ isCorporate }) => {
  const SwitchMenuQuery = useStaticQuery(
    graphql`
      query MyQuery {
        contentfulMenu(contentful_id: { eq: "5BIyks5rnkjhT0vzY0ioir" }) {
          subitems {
            id
            title
            link {
              ... on Node {
                id
                ... on ContentfulDetailPage {
                  slug
                }
                ... on ContentfulPage {
                  slug
                }
              }
            }
          }
        }
      }
    `
  );

  const setPartiallyActive = (slug) => {
    const corporatePaths = [
      'cooperatie',
      'coöperatie',
      '/cooperatie',
      '/coöperatie',
    ];
    const isCorporateItem = corporatePaths.some((path) =>
      slug.startsWith(path)
    );
    return (
      (isCorporate && isCorporateItem) || (!isCorporate && !isCorporateItem)
    );
  };

  return (
    <Wrapper>
      <Container>
        <ContainerInner>
          {SwitchMenuQuery?.contentfulMenu?.subitems?.map(
            ({ id, title, link }) => (
              <React.Fragment key={id}>
                {link ? (
                  <StyledLink
                    fade="true"
                    duration={2}
                    to={formatSlug(link.slug)}
                    activeClassName="active"
                    partiallyActive={setPartiallyActive(formatSlug(link.slug))}
                  >
                    {title}
                  </StyledLink>
                ) : (
                  <StyledLinkDummy>{title}</StyledLinkDummy>
                )}
              </React.Fragment>
            )
          )}
        </ContainerInner>
      </Container>
    </Wrapper>
  );
};

export default SiteSwitch;
