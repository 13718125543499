import React from 'react';

import {
  TableWrapper,
  TableElement,
  TableHead,
  TableBody,
  TableHeadColumn,
  TableRow,
  TableColumn,
} from './styles';

const Table = ({ data }) => {
  return (
    <TableWrapper>
      <TableElement>
        <TableHead>
          {data && (
            <TableRow>
              {data &&
                data[0].map((item) => (
                  <TableHeadColumn key={item}>{item}</TableHeadColumn>
                ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {data &&
            data
              .slice(1)
              .map((items) => (
                <TableRow key={items}>
                  {items &&
                    items.map((item) => (
                      <TableColumn key={item}>{item}</TableColumn>
                    ))}
                </TableRow>
              ))}
        </TableBody>
      </TableElement>
    </TableWrapper>
  );
};

export default Table;
