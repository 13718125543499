import React from 'react';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Table from './components/Table/Table';

export function renderContent(richText) {
  const options = {
    renderText: (currentText) =>
      currentText.split('\n').flatMap((text, index) => {
        return [index > 0 && <br key={index} />, text];
      }),
    renderNode: {
      /*eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }]*/
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const targetItem = node.data.target;
        if (targetItem.__typename === 'ContentfulParagraphTable') {
          return <Table data={targetItem.table.tableData} />;
        }
        return <></>;
      },
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
      /*eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }]*/
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const targetItem = node.data.target;
        if (targetItem.__typename === 'ContentfulAsset') {
          return (
            <a
              key={targetItem.contentful_id}
              href={targetItem.file.url}
              title={targetItem.file.fileName}
              download
            >
              {children}
            </a>
          );
        }
        return <></>;
      },
    },
  };

  return renderRichText(richText, options);
}

export function mapTypeNameToComponentName(typename) {
  return typename?.replace('Contentful', '');
}

export const formatSlug = (
  slug,
  removeLeadingSlash = false,
  stylize = false
) => {
  if (!slug) return '';

  let formattedSlug = slug;

  if (stylize) {
    const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
    const to = 'aaaaaeeeeeiiiiooooouuuunc------';

    formattedSlug = slug
      .split('')
      .map((letter, i) =>
        letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      );

    formattedSlug = formattedSlug
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/&/g, '-en-')
      .replace(/[^\w-/]+/g, '')
      .replace(/--+/g, '-');
  }

  if (removeLeadingSlash && slug.startsWith('/')) {
    formattedSlug = slug.substr(1);
  } else if (!removeLeadingSlash && !slug.startsWith('/')) {
    formattedSlug = `/${slug}`;
  }

  return formattedSlug;
};
