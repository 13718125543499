import React from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.a`
  position: relative;
  margin-top: -6px;
  z-index: 10;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    display: none;
  }
`;

const ToggleItem = styled.div`
  width: 22px;
  height: 16px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;

  span {
    display: block;
    position: absolute;
    height: 2px;
    background: ${(props) => props.theme.colors.greyDarkest};
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 5px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 12px;
      width: 50%;
    }

    &:nth-child(4) {
      top: 19px;
    }

    ${(props) =>
      props.active &&
      `
    &:nth-child(1) {
      top: 22px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2),
    &:nth-child(3) {
      width: 100%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 16px;
      width: 0%;
      left: 50%;
    }
	`}
  }
`;

const Toggle = (props) => {
  return (
    <ToggleWrapper onClick={props.onClickAction}>
      <ToggleItem active={props.toggleActive}>
        <span />
        <span />
        <span />
        <span />
      </ToggleItem>
    </ToggleWrapper>
  );
};

export default Toggle;
