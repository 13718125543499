/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require('react');
const { ThemeProvider, createGlobalStyle } = require('styled-components');
const { Normalize } = require('styled-normalize');

require('./src/fonts/fonts.css');
const theme = require('./src/theme.js');
const typography = require('./src/typography.js');
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
		color: ${theme.default.colors.greyDarkest};
    background-color: ${theme.default.colors.backgroundColor};
    font-size: 16px;
    font-family: AdelleSansRegular;

    @media (min-width: ${(props) => props.theme.breakpoints.m}) {
      font-size: 18px;
    }
  }
  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
  a,
  button {
    &:focus {
      outline-color: ${theme.default.colors.brand};
    }
  }
  .screenreader {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
	.menu-open {
		max-height: 100vh;
		overflow: hidden;
	}
	@media screen and (max-width: 480px) {
		.menu-open {
			overflow:visible;
			max-height:none;
		}
	}
	* {
		scroll-behavior: smooth;
		box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1 {
    ${typography.default.h1}
  }
  h2 {
    ${typography.default.h2}
  }
  h3 {
    ${typography.default.h3}
  }
  h4 {
    ${typography.default.h4}
  }
  h5 {
    ${typography.default.h5}
  }
  h6 {
    ${typography.default.h6}
  }
  p {
    ${typography.default.body}
    color: ${theme.default.colors.black};
  }
  .tl-edges {
    overflow-y: hidden;
  }

  @-webkit-keyframes SlideIn {
   0% {
     opacity: 0;
     transform: translate3d(-300px, 0, 0);
   }

   40% {
     opacity: 1;
     transform: translate3d(10px, 0, 0);
   }

   50% {
     transform: translate3d(-10px, 0, 0);
   }

   to {
     transform: translate3d(0, 0, 0);
   }
 }
`;

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme.default}>
      <React.Fragment>
        <Normalize />
        <GlobalStyle />

        {element}
      </React.Fragment>
    </ThemeProvider>
  );
};
