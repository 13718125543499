import styled from 'styled-components';
import Settings from '../../settings';
import Button from '../Button/Button';

export const WrappedHeader = styled.header`
  position: ${({ sticky }) => (sticky ? 'fixed' : 'absolute')};
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.backgroundColor};
  transform: ${({ show, sticky }) =>
    sticky && !show ? 'translateY(calc(-100% - 60px))' : 'translateY(0)'};
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
  ${({ transition }) =>
    transition &&
    'transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;'}
  ${({ sticky }) => sticky && 'box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.08);'}

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    background-color: ${(props) =>
      props.sticky ? props.theme.colors.backgroundColor : 'transparent'};
    ${({ sticky }) => !sticky && 'transform: translateY(0);'}

    transform: ${({ show, sticky }) => {
      if (sticky && !show) {
        return 'translateY(calc(-100% - 60px))';
      } else if (show && sticky && Settings.hasSiteSwitch) {
        return 'translateY(-40px)';
      } else {
        return 'translateY(0)';
      }
    }}
  }

  .hDTYmu {
    z-index: 10;
  }
`;

export const FlexHeader = styled.div`
  position: static;
  display: flex;
  align-items: center;
  padding: 20px 0;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    position: relative;
    justify-content: flex-start;
    padding: ${({ sticky }) => (sticky ? '5px 0' : '20px 0')};
    transition: padding 0.3s ease-out;
  }
`;

export const LogoWrapper = styled.div`
  ${({ centered }) => {
    if (centered) {
      return 'position: absolute; left: 50%; transform: translateX(-50%) translateY(-50%);';
    } else {
      return 'position: absolute; left: 60px; transform: translateY(-50%);';
    }
  }}

  ${() => {
    if (Settings.headerLogoOverflowMode === 'none') {
      return 'top: 50%;';
    } else {
      return 'top: 100%;';
    }
  }}
  z-index: 10;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    ${() => {
      if (Settings.headerLogoOverflowMode === 'none') {
        return 'position: relative;';
      } else {
        return 'position: absolute;';
      }
    }}
    left: 0;
    top: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '10px';
      } else if (!sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '20px';
      } else {
        return '0';
      }
    }};
    transform: none;
    transition: top 0.3s ease-out;
  }
`;

export const Logo = styled.img`
  display: block;
  height: 100%;
  width: 80px;
  ${({ shadow }) =>
    shadow && `filter: drop-shadow(0 12px 12px rgba(0, 0, 0, .2));`}

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    width: ${({ sticky }) => {
      if (sticky && Settings.headerLogoOverflowMode !== 'none') {
        return '48px';
      } else {
        return '104px';
      }
    }};
    transition: width 0.3s ease-out;
  }
`;

export const StyledLink = styled.a`
  position: relative;
  padding: 10px 0;
  color: ${(props) => props.theme.colors.brand};
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.black};
  }
`;

export const StyledButton = styled(Button)`
  margin: 0;
`;

export const Actions = styled.div`
  font-family: AdelleRegular;

  @media (min-width: ${(props) => props.theme.breakpoints.l}) {
    margin-left: auto;

    a {
      font-size: 16px;
    }
  }
`;
