import React from 'react';
import PropTypes from 'prop-types';
import Theme from '../../theme';

import { StyledTag } from './styles';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  headerMedium: 'h2',
  headerSmall: 'h3',
  subline: 'h4',
  caption: 'caption',
  bodySmall: 'p',
  body: 'p',
  bodyLarge: 'p',
  label: 'label',
  footnote: 'small',
  srOnly: 'span',
};

const Typogaphy = ({
  tag,
  variant = 'body',
  color = Theme.colors.black,
  lineHeight,
  align,
  children,
  ...rest
}) => (
  <StyledTag
    as={tag || variantMapping[variant]}
    variant={variant}
    textcolor={color}
    align={align}
    lineHeight={lineHeight}
    {...rest}
  >
    {children}
  </StyledTag>
);

Typogaphy.propTypes = {
  tag: PropTypes.elementType,
  lineHeight: PropTypes.string,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'bodySmall',
    'body',
    'bodyMedium',
    'label',
    'footnote',
    'caption',
    'subline',
    'headerMedium',
    'headerSmall',
    'srOnly',
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  color: PropTypes.string,
  children: PropTypes.node,
};

/* eslint react/require-default-props: "off"  */
Typogaphy.defaultProps = {
  variant: 'body',
  color: Theme.colors.black,
};

export default Typogaphy;
