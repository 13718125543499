import React from 'react';
import FooterTop from './FooterTop';
import FooterData from './FooterData';
import FooterSocial from './FooterSocial';
import Container from '../Container';
import Spacer from '../Spacer';

import { StyledFooter, FooterContainer } from './styles';

const Footer = ({ data, menu, submenus }) => {
  const {
    facebookLink,
    instagramLink,
    pinterestLink,
    youTubeLink,
    linkedInLink,
  } = data;

  return (
    <StyledFooter id="footer">
      <h2 className="screenreader">{menu?.title}</h2>
      {submenus && <FooterTop submenus={submenus} />}

      <Spacer>
        <Container>
          <FooterContainer>
            <FooterData data={data} menu={menu?.subitems} />

            <FooterSocial
              facebook={facebookLink}
              instagram={instagramLink}
              pinterest={pinterestLink}
              youtube={youTubeLink}
              linkedin={linkedInLink}
            />
          </FooterContainer>
        </Container>
      </Spacer>
    </StyledFooter>
  );
};

export default Footer;
