export default {
  colors: {
    brand: '#E50006',
    brandDark: '#3C0002',
    red: '#E50006',
    green: '#AED136',
    orange: '#F89E1C',
    purple: '#A63D96',
    pink: '#E83C96',
    blue: '#58CCFF',
    turquoise: '#38BCAB',
    black: '#000000',
    greyDarkest: '#373737',
    greyDark: '#5F5F5F',
    grey: '#777777',
    greyLight: '#AAAAAA',
    greyLighter: '#E7E7E7',
    greyLightest: '#F4F4F4',
    white: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    bannerOrb: '#38BCAB',
  },
  spacing: {
    xs: '0.25rem',
    s: '0.5rem',
    m: '1rem',
    l: '2rem',
    xl: '3rem',
    xxl: '4rem',
    xxl2: '8rem',
  },
  breakpoints: {
    xs: '21.438em', // 343 pixels
    s: '30em', // 480 pixel
    m: '48em', // 768 pixels
    l: '64em', // 1024 pixels
    xl: '80em', // 1280 pixels
    xxl: '90em', // 1440 pixels
    xxxl: '100em', // 1600 pixels
  },
  grid: {
    s: '996px',
    m: '1152px',
    l: '1440px',
    xl: '1600px',
  },
  typography: [
    16 / 18,
    18 / 18,
    24 / 18, //h6
    32 / 18, //h5
    40 / 18, //h4
    48 / 18, //h3
    56 / 18, //h2
    72 / 18, //h1
  ],
  typographyTablet: [
    16 / 18,
    16 / 18,
    22 / 18, //h6
    32 / 18, //h5
    36 / 18, //h4
    42 / 18, //h3
    50 / 18, //h2
    60 / 18, //h1
  ],
  typographyMobile: [
    16 / 18,
    16 / 18,
    20 / 18, //h6
    32 / 18, //h5
    32 / 18, //h4
    36 / 18, //h3
    38 / 18, //h2
    40 / 18, //h1
  ],
  screenreader: `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
  `,
};
