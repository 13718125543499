import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useMedia } from 'react-use-media';
import logo from '../../images/logo.svg';
import Toggle from '../Toggle/Toggle';
import SiteSwitch from '../SiteSwitch/SiteSwitch';
import MainMenu from '../MainMenu/MainMenu';
import Container from '../Container';
import Theme from '../../theme';
import Settings from '../../settings';

import {
  WrappedHeader,
  FlexHeader,
  LogoWrapper,
  Logo,
  StyledLink,
  StyledButton,
  Actions,
} from './styles';

const Header = ({
  isCorporate,
  isMobile,
  isMenuToggled,
  toggleMenu,
  location,
  corporateCheckRunning,
}) => {
  const MenuQuery = useStaticQuery(graphql`
    query {
      allContentfulSettings(filter: { node_locale: { eq: "nl-NL" } }) {
        edges {
          node {
            extranet
            callToActionLabel
            displayCallToActionAsButton
            cooperatieMenu {
              ...MainMenuFragment
            }
            consumerMenu {
              ...MainMenuFragment
            }
          }
        }
      }
    }
  `);
  const menuSettings = MenuQuery.allContentfulSettings.edges[0].node;
  const corporateMenuData = menuSettings.cooperatieMenu;
  const consumerMenuData = menuSettings.consumerMenu;
  const extranetUrl = menuSettings.extranet;
  const { callToActionLabel, displayCallToActionAsButton } = menuSettings;
  const ref = useRef();
  const [rootUrl, setRootUrl] = useState('/');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [show, setShow] = useState(true);
  const [sticky, setSticky] = useState(false);
  const [transition, setTransition] = useState(true);
  const isDesktop = useMedia({
    minWidth: Theme.breakpoints.l,
  });
  const [menuData, setmenuData] = useState(null);

  const handleScroll = useCallback(() => {
    const boundTop = document.body.getBoundingClientRect().top;

    setTransition(sticky && boundTop <= -60);
    setScrollPosition(boundTop);
    setShow(boundTop > scrollPosition);

    if (!sticky) {
      setSticky(boundTop < -60);
    }

    if (
      (isDesktop && boundTop === 130) ||
      (!isDesktop && boundTop === 60) ||
      (isDesktop && !Settings.hasSiteSwitch && boundTop === 90)
    ) {
      setSticky(false);
    }
  }, [sticky, isDesktop, scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    if (isCorporate) {
      const splitPath = location.pathname.split('/');
      setRootUrl(splitPath[0] !== '' ? `/${splitPath[0]}` : `/${splitPath[1]}`);
    } else {
      setRootUrl('/');
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, isCorporate, handleScroll]);

  useEffect(() => {
    if (isCorporate && !corporateCheckRunning) {
      setmenuData(corporateMenuData);
    } else {
      setmenuData(consumerMenuData);
    }
  }, [isCorporate, corporateMenuData, consumerMenuData, corporateCheckRunning]);

  return (
    <WrappedHeader
      id="header"
      show={show}
      ref={ref}
      sticky={sticky}
      transition={transition}
    >
      {!isMobile || (isMenuToggled && isMobile && Settings.hasSiteSwitch) ? (
        <SiteSwitch isCorporate={isCorporate} />
      ) : null}

      <Container>
        <FlexHeader sticky={sticky}>
          <Toggle toggleActive={isMenuToggled} onClickAction={toggleMenu} />

          <LogoWrapper
            sticky={sticky}
            centered={Settings.headerLogoMobileCentered}
          >
            <AniLink fade="true" duration={2} to={rootUrl}>
              {Settings.headerLogoOverflowMode === 'none' ? (
                <Logo src={logo} alt="Logo Biaretto" sticky={sticky} />
              ) : (
                <Logo src={logo} alt="Logo Quantore" sticky={sticky} shadow />
              )}
            </AniLink>
          </LogoWrapper>
          {(!isMobile && menuData && !corporateCheckRunning) ||
          (isMenuToggled && isMobile) ? (
            <MainMenu data={menuData} sticky={sticky} location={location} />
          ) : null}

          {extranetUrl && callToActionLabel && (
            <Actions>
              {displayCallToActionAsButton === true ? (
                <StyledButton content={callToActionLabel} href={extranetUrl} />
              ) : (
                <StyledLink href={extranetUrl}>{callToActionLabel}</StyledLink>
              )}
            </Actions>
          )}
        </FlexHeader>
      </Container>
    </WrappedHeader>
  );
};

export default Header;
