import React from 'react';

import {
  Facebook,
  LinkedIn,
  Pinterest,
  Youtube,
  Instagram,
} from '../../images/svg/Icons';

import {
  SocialList,
  SocialListItem,
  FacebookLink,
  InstagramLink,
  LinkedInLink,
  PinterestLink,
  YoutubeLink,
} from './styles';

const FooterSocial = ({
  facebook,
  instagram,
  linkedin,
  pinterest,
  youtube,
}) => (
  <div>
    <SocialList>
      {facebook && (
        <SocialListItem>
          <FacebookLink href={facebook}>
            <Facebook />
          </FacebookLink>
        </SocialListItem>
      )}
      {instagram && (
        <SocialListItem>
          <InstagramLink href={instagram}>
            <Instagram />
          </InstagramLink>
        </SocialListItem>
      )}
      {linkedin && (
        <SocialListItem>
          <LinkedInLink href={linkedin}>
            <LinkedIn />
          </LinkedInLink>
        </SocialListItem>
      )}
      {pinterest && (
        <SocialListItem>
          <PinterestLink href={pinterest}>
            <Pinterest />
          </PinterestLink>
        </SocialListItem>
      )}
      {youtube && (
        <SocialListItem>
          <YoutubeLink href={youtube}>
            <Youtube />
          </YoutubeLink>
        </SocialListItem>
      )}
    </SocialList>
  </div>
);

export default FooterSocial;
